export const terms =
"<div class='server_agreement'>\
<p>\
  D5D支付-银行卡快捷支付服务协议（以下简称“本协议”）是上海迪志计算机科技有限公司（以下简称“上海迪志计算机科技公司”）与您(以下简称“您”)就“快捷支付服务”（以下简称“本服务”）的使用等相关事项所订立的有效合约。\
</p>\
<p>\
  您通过网络页面点击确认或以其他方式选择接受本协议，即表示您与上海迪志计算机科技公司已达成协议并同意接受本协议的全部约定内容。在接受本协议之前，请您仔细阅读本协议的全部内容（特别是以<strong\
    ><u>粗体下划线</u></strong\
  >标注的内容）。如果您不同意本协议的任意内容， 请不要进行后续操作。\
</p>\
<p>\
  <strong\
    >上海迪志计算机科技公司有权对本协议、规则等进行调整和补充或对D5D支付系统在功能上进行减少、增加或作其它改变，上海迪志计算机科技公司将以网页公告等方式对该等变更予以提前公布。变更后的条款自公布满30日的0：00起生效。若您在上海迪志计算机科技公司变更的条款生效后，仍继续使用本服务的，则视为接受该等变更，若您不同意的，您有权终止本协议并停止使用本服务。</strong\
  >\
</p>\
<p>\
  <strong\
    >您同意上海迪志计算机科技公司收集您在使用本服务过程中所填写的姓名、银行卡卡号、手机号码、身份证号码及其他银行要求的身份信息、银行卡账户信息或卡面信息等，并将该等信息提交银行进行校对核验，以便判断您能否开通本协议项下的快捷支付服务。同时上海迪志计算机科技公司与银行保留随时变更上述作为校验标准的要素的权利。</strong\
  >\
</p>\
<p>\
  当您成功开通快捷支付服务后，<strong>无需输入银行卡密码即可完成支付</strong>。<strong\
    >银行会根据D5D支付传输的您的支付指令从您的银行卡账户中扣除有关款项给收款方。</strong\
  >\
</p>\
<p>\
  <strong\
    >您在使用本服务时，应当认真确认交易信息，包括但不限于商品名称、数量、金额等。您在D5D支付网站或相关使用界面输入必要的密保措施（预先设定的支付密码、密保信息，及或您所收到的手机校验码等一种或多种验证措施）后，即视为您确认支付金额及该支付指令并已不可撤销地向上海迪志计算机科技公司发出指令，您不可撤销的授权上海迪志计算机科技公司根据您的指令委托银行或第三方从您银行卡中划扣资金给收款方。届时您不应以非本人意愿交易等原因要求上海迪志计算机科技公司退款或承担其他责任。</strong\
  >\
</p>\
<p><strong>您知悉，您在使用本服务时需同时受银行和D5D支付设置的支付限额的约束，您对此无异议。</strong></p>\
<p>\
  您承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在D5D邮箱账号注册资料中出现违法和不良信息，且您保证其在注册和使用账号时，不得有以下情形：\
</p>\
<ol style='margin:0 2rem;'>\
  <li>1）违反宪法或法律法规规定的；</li>\
  <li>2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</li>\
  <li>3）损害国家荣誉和利益的，损害公共利益的；</li>\
  <li>4）煽动民族仇恨、民族歧视，破坏民族团结的；</li>\
  <li>5）破坏国家宗教政策，宣扬邪教和封建迷信的；</li>\
  <li>6）散布谣言，扰乱社会秩序，破坏社会稳定的</li>\
  <li>7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>\
  <li>8）侮辱或者诽谤他人，侵害他人合法权益的；</li>\
  <li>9）含有法律、行政法规禁止的其他内容的。</li>\
</ol>\
<p>D5D邮箱账号注册资料包括但不限于您的D5D邮箱账号名称、头像、密码、注册或更新邮箱账号时输入的所有信息。</p>\
<p>根据相关法律、法规规定以及考虑到D5D产品服务的重要性，您同意：</p>\
<ol style='margin:0 2rem;'>\
  <li>1）在注册D5D邮箱账号时提交个人有效身份信息进行实名认证；</li>\
  <li>2）提供及时、详尽及准确的账户注册资料；</li>\
  <li>3）不断更新注册资料，符合及时、详尽准确的要求，对注册D5D邮箱账号时填写的身份证件信息不能更新。</li>\
</ol>\
<p>\
  若您提供给上海迪志计算机科技公司的账号注册资料不准确，不真实，含有违法或不良信息的，上海迪志计算机科技公司有权不予注册，并保留终止您使用D5D各项服务的权利。若您以虚假信息骗取账号注册或账号头像、个人简介等注册资料存在违法和不良信息的，上海迪志计算机科技公司有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用关联机构或社会名人注册账号名称的，上海迪志计算机科技公司有权注销该账号，并向政府主管部门进行报告。\
</p>\
<p>\
  <strong\
    >您应确保开通本服务所提供的手机号码为本人所有，并授权上海迪志计算机科技公司可以通过第三方渠道对您所提供手机号码的真实性、有效性进行核实。</strong\
  >\
</p>\
<p>\
  <strong\
    ><u\
      >您应确保您在开通和使用本服务时的银行卡为您本人的银行卡，确保您使用该银行卡的行为合法、有效，未侵犯任何第三方合法权益；否则因此造成上海迪志计算机科技公司及其关联公司、持卡人损失的，您应负责赔偿并承担全部法律责任，上海迪志计算机科技公司有权采取合理措施减轻该损失，包括但不限于对您的D5D支付账户及资金进行止付、根据有权机关的协助执行要求从您的D5D支付账户扣除相应的款项等（如有）</u\
    ></strong\
  >。\
</p>\
<p>\
  <strong\
    >您应妥善保管银行卡和D5D邮箱账号信息，包括但不限于银行卡卡号、密码、有效期，D5D邮箱账号、D5D邮箱密码、D5D支付账户（如有）、支付密码、密保信息、手机校验码等信息。如您遗失银行卡、泄露D5D邮箱账号密码、支付密码或相关信息的，建议您及时通知发卡行及/或<u>上海迪志计算机科技公司</u>，以减少可能发生的损失。无论是否已通知发卡行及/或上海迪志计算机科技公司，<u\
      >因您自身原因泄露上述银行卡信息、D5D邮箱账号信息、支付密码信息或丢失银行卡等所致损失需由您自行承担。</u\
    ></strong\
  >\
</p>\
<p>\
  您在对开通、使用本服务过程中所填写信息、发出指令的真实性及有效性承担全部责任；<strong\
    ><u\
      >您承诺，上海迪志计算机科技公司依照您的指令进行操作的一切法律后果由您承担。且您认可您的快捷支付的使用记录数据、交易金额数据等均以D5D支付系统平台记录的数据为准</u\
    ></strong\
  >。同时您授权\
  <u>上海迪志计算机科技公司</u\
  >有权留存您的该等数据信息作为其常规商业档案的一部分，并以为更好地向您提供其他服务而使用。\
</p>\
<p>\
  <strong\
    ><u\
      >您理解并同意出现下列情况之一时，上海迪志计算机科技公司有权停止为您提供本服务、采取相关措施，并向银行传递该信息。但事后上海迪志计算机科技公司应通过站内信、邮件、短信等通知方式向您履行告知义务：</u\
    ></strong\
  >\
</p>\
<ol style='margin:0 2rem;'>\
  <li>1）您登录D5D邮箱账号，主动取消绑定或停止使用本服务；</li>\
  <li>2）您的实际支付金额大于支付限额的；</li>\
  <li>3）您的银行卡销户、有效期届满、挂失等状态异常或余额不足的；</li>\
  <li>\
    4）上海迪志计算机科技公司依据其自身判断发现您在使用快捷支付服务时涉嫌欺诈或被他人控制并用于欺诈目的，本情况下，上海迪志计算机科技公司有权采取合理措施，包括但不限于对您的D5D支付账户及资金（如有）进行止付、中止或终止提供快捷支付服务，或根据有权机关的协助执行要求处置涉嫌欺诈的资金等；\
  </li>\
  <li>\
    5）有证据证明您涉嫌利用本服务进行套现、虚假交易、洗钱或任何其他非法活动、欺诈或违反诚信原则的行为的，或您拒绝配合上海迪志计算机科技公司进行关于您是否进行上述信息的相关调查的；\
  </li>\
  <li>\
    6）上海迪志计算机科技公司发现您违反法律法规等规定或本协议约定的情形，或违反上海迪志计算机科技公司及/或其关联公司网站的条款、协议、规则、通告等相关规定，而被上述任一网站终止提供服务的；\
  </li>\
</ol>\
<p>\
  您同意，本协议适用中华人民共和国大陆地区法律。因上海迪志计算机科技公司与您就本协议的签订、履行或解释发生争议，双方应努力友好协商解决<strong\
    >。<u>如协商不成，</u>上海迪志计算机科技公司<u\
      >和您同意由杭州市滨江区人民法院管辖审理双方的纠纷或争议。</u\
    ></strong\
  >\
</p>\
<p>\
  <strong\
    ><u>本协议内容包括协议正文及所有</u>上海迪志计算机科技公司<u\
      >已经发布的或将来可能发布的D5D支付服务的使用规则。所有规则为本协议不可分割的一部分，与协议正文具有相同法律效力</u\
    ></strong\
  >。若您在本协议内容发生修订后，继续使用本服务的，则视为您同意最新修订的协议内容；否则您须立即停止使用本服务。本协议未尽事宜，您需遵守D5D支付网站上公布的《D5D支付服务协议》及相关规则。\
</p>\
</div>"
