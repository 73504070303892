<template>
  <main>
    <div class="container" v-if="!loading">
      <h3 class="text-white text-center mb-5">{{ title }}</h3>
      <p class="text-secondary" style="font-size:14px" v-html="content"></p>
    </div>
    <div v-else class="d-flex items-center justify-center py-5">
      <spinner />
    </div>
  </main>
</template>

<script>
import { AppService } from '@/services/api'
import { terms } from './terms'

export default {
  name: 'Page',
  data() {
    return {
      title: null,
      content: null,
      loading: false,
    }
  },
  computed: {
    param() {
      return this.$route.params.page
    },
  },
  watch: {
    param() {
      this.loadPage()
    },
  },
  async mounted() {
    this.loadPage()
  },
  methods: {
    async loadPage() {
      this.loading = true
      console.log(this.param)
      if (this.param === 'terms') {
        this.title = '服务协议'
        this.content = terms
        this.loading = false
        return
      }
      try {
        const { data } = await AppService.getPage(this.$route.params.page)
        this.title = data.title
        this.content = data.content
      } catch {
        this.$router.push('/*')
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin-top: 100px;
}
</style>
